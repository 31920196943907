export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function getBrowserId() {
  const id = window.localStorage.getItem("browser");

  if (id != null) {
    return id;
  }

  const newId = uuidv4();
  window.localStorage.setItem("browser", newId);

  return newId;
}

export function getSavedDevices() {
  let devices = window.localStorage.getItem("saved-devices");

  if (devices == null) {
    return null;
  }

  try {
    let deviceArray = JSON.parse(devices);
    
    if (deviceArray.length > 0) {
      return deviceArray;
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

export function addSavedDevice(device) {
  removeSavedDevice(device);

  let devices = getSavedDevices();

  if (devices == null) {
    devices = [];
  }

  devices.push({
    "id": device.id,
    "name": device.name,
    "user": device.user,
    "device": device.device
  });

  window.localStorage.setItem("saved-devices", JSON.stringify(devices));
}

export function removeSavedDevice(device) {
  let devices = getSavedDevices();

  if (devices == null) {
    devices = [];
  }

  const savedDevices = devices.filter((dev) => {
    if (dev.user.toUpperCase() === device.user.toUpperCase() && dev.device.toUpperCase() === device.device.toUpperCase()) {
      return false
    } else {
      return true
    }
  });

  window.localStorage.setItem("saved-devices", JSON.stringify(savedDevices));
}
