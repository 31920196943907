import React from "react"
import { ClassicSpinner } from "react-spinners-kit"

import { QRCodeSVG } from "qrcode.react"

import "./QRDisplay.css"

import { HeaderText } from "./HeaderText.js"

export default function QRDisplay(props) {
    return (
        <div className="QRDisplay-main">
            <HeaderText>Connect iPhone to start WiFi Transfer</HeaderText>

            <div className="QRDisplay-code">
                {props.code ? <QRCodeSVG size={220} value={props.code} /> : "Loading..."}
            </div>

            <div className="QRDisplay-bottom">
                {props.code ? (
                    <span className="QRDisplay-code-text">
                        Or enter the code <b>{props.code}</b>
                    </span>
                ) : null}

                <ClassicSpinner size={18} color="white" loading={true} />

                {props.code ? <span className="QRDisplay-bottom-waiting">Waiting for iPhone to connect</span> : null}
            </div>
        </div>
    )
}
