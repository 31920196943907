import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"
import { ClassicSpinner } from "react-spinners-kit"
import { HashRouter as Router, Switch, Route, Redirect, useHistory } from "react-router-dom"

import { getSavedDevices, addSavedDevice, removeSavedDevice, uuidv4 } from "./saved-devices.js"

import QRDisplay from "./components/QRDisplay"
import { HeaderText } from "./components/HeaderText.js"
import { PrimaryButton, SecondaryButton, DestructiveButton } from "./components/Button.js"

import { ReactComponent as CrossIcon } from "./components/times.svg"

import "./App.css"

const SOCKET_PING_INTERVAL = 30000

const API_URL = (path) => {
    const url = new URL("/api" + (path || ""), window.location.href)

    if (url.port === "3000" || url.port === 3000) {
        url.port = 8989
    }

    return url
}

function timeoutPromise(ms, promise) {
    return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject(new Error("promise timeout"))
        }, ms)
        promise.then(
            (res) => {
                clearTimeout(timeoutId)
                resolve(res)
            },
            (err) => {
                clearTimeout(timeoutId)
                reject(err)
            }
        )
    })
}

function ScanCode({ code, ...props }) {
    return <QRDisplay code={code} />
}

function DeviceList({ devices, connectDevice, removeDevice, device, code, ...props }) {
    let history = useHistory()

    useEffect(() => {
        if (device == null || code == null) {
            return
        }

        ;(async () => {
            const url = API_URL("/v0/request-device")

            const res = await window.fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    code: code,
                    push_token: {
                        user: device.user,
                        device: device.device,
                    },
                }),
            })

            console.log(await res.json())
        })()
    }, [device, code])

    if (device != null) {
        return (
            <div className="QRDisplay-main" style={{ color: "white" }}>
                <HeaderText>Connecting to {device.name}</HeaderText>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "3rem" }}>
                    <ClassicSpinner size={18} color="white" loading={true} />
                </div>

                <p>A notification has been sent to {device.name}. Open the notification to finish connecting.</p>
            </div>
        )
    }

    if (devices == null || devices.length === 0) {
        return <Redirect to="/code" />
    }

    const confirmRemove = (device) => {
        const deviceName = (device.name || "").length > 0 ? device.name : "this device"

        if (window.confirm(`Remove ${deviceName}?`)) {
            removeDevice(device)
        }
    }

    const DeviceList = styled.ul`
        padding: 0;
        list-style: none;
        text-align: left;
        display: flex;
        align-items: stretch;
        flex-direction: column;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
    `

    const DeviceListItem = styled.li`
        flex-grow: 1;
        display: flex;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    `

    return (
        <div className="QRDisplay-main" style={{ color: "white" }}>
            <HeaderText>Connect iPhone to start WiFi Transfer</HeaderText>

            <DeviceList>
                {devices.map((device, idx) => (
                    <DeviceListItem key={`${device.user}:${device.device}`}>
                        <span style={{ flexGrow: 1 }}>
                            {(device.name || "").length > 0 ? device.name : "Unknown device"}
                        </span>
                        <span style={{ flexShrink: 0 }}>
                            <PrimaryButton
                                onClick={() => {
                                    connectDevice(device)
                                }}
                            >
                                Connect
                            </PrimaryButton>
                            <DestructiveButton
                                title="Remove"
                                onClick={() => {
                                    confirmRemove(device)
                                }}
                                style={{ marginLeft: "8px" }}
                            >
                                <CrossIcon height="1em" />
                            </DestructiveButton>
                        </span>
                    </DeviceListItem>
                ))}
            </DeviceList>

            <div style={{ padding: "8px 16px" }}>
                <SecondaryButton
                    onClick={() => {
                        history.push("/code")
                    }}
                >
                    Scan new device
                </SecondaryButton>
            </div>
        </div>
    )
}

function handlePairComplete(devicePrimaryURL, deviceSecondaryURL) {
    return Promise.resolve(devicePrimaryURL)

    // if (deviceSecondaryURL == null) {
    //     return Promise.resolve(devicePrimaryURL)
    // }

    //     return timeoutPromise(
    //         15000,
    //         window.fetch(devicePrimaryURL, {
    //             mode: "no-cors",
    //             method: "HEAD",
    //         })
    //     )
    //         .then(() => {
    //             completeConnection(devicePrimaryURL)
    //         })
    //         .catch((err) => {
    //             if (deviceSecondaryURL == null) {
    //                 return Promise.reject(err)
    //             }
    //
    //             return timeoutPromise(
    //                 15000,
    //                 window.fetch(deviceSecondaryURL, {
    //                     mode: "no-cors",
    //                     method: "HEAD",
    //                 })
    //             ).then(() => {
    //                 completeConnection(deviceSecondaryURL)
    //             })
    //         })
    //         .catch((err) => {
    //             console.error(err)
    //         })
}

export default function App({ ...props }) {
    const [code, setCode] = useState(null)
    const [devices, setDevices] = useState(getSavedDevices())
    const [connectedDevice, setConnectedDevice] = useState(null)
    const [uuid] = useState(uuidv4())

    const removeDevice = useCallback(
        (device) => {
            removeSavedDevice(device)
            setDevices(getSavedDevices())
        },
        [setDevices]
    )

    const completeConnection = useCallback(
        (url, pushToken) => {
            if (pushToken != null) {
                addSavedDevice(pushToken)
            }

            setConnectedDevice(null)
            window.location.href = url
        },
        [setConnectedDevice]
    )

    const openWebsocket = useCallback(() => {
        const url = API_URL("/v1/code")
        url.searchParams.set("id", uuid)

        if (url.host === "doppler-transfer.com") {
            url.protocol = "wss"
        } else {
            url.protocol = "ws"

            if (url.port === "3000" || url.port === 3000) {
                url.port = 8989
            }
        }

        const ws = new WebSocket(url.href)

        ws.onmessage = (message) => {
            const data = JSON.parse(message.data)

            if (data.code) {
                setCode(data.code)
            } else if (data.type === "exists" && data.device != null) {
                const isDeviceSaved = (devices || []).some((device) => device.id === data.device)
                ws.send(
                    JSON.stringify({
                        type: "exists",
                        device: data.device,
                        is_saved: isDeviceSaved,
                    })
                )
            } else if (data.url_bonjour) {
                handlePairComplete(data.url_bonjour, data.url_lan)
                    .then((url) => completeConnection(url, data.push_token))
                    .catch((err) => console.error(err))
            } else if (data.url_lan) {
                handlePairComplete(data.url_lan)
                    .then((url) => completeConnection(url, data.push_token))
                    .catch((err) => console.error(err))
            } else if (data.url) {
                handlePairComplete(data.url)
                    .then((url) => completeConnection(url, data.push_token))
                    .catch((err) => console.error(err))
            }
        }

        ws.onopen = () => {
            setInterval(() => {
                ws.send("PING")
            }, SOCKET_PING_INTERVAL)
        }

        ws.onclose = () => {
            setCode(null)
        }
    }, [uuid, devices, setCode, completeConnection])

    useEffect(() => {
        if (code == null) {
            openWebsocket()
        }
    }, [code, openWebsocket])

    return (
        <div className="App-main">
            <Router>
                <Switch>
                    <Route path="/code">
                        <ScanCode code={code} />
                    </Route>

                    <Route path="/">
                        <DeviceList
                            devices={devices}
                            connectDevice={setConnectedDevice}
                            removeDevice={removeDevice}
                            code={code}
                            device={connectedDevice}
                        />
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}
