import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  font: inherit;
  font-size: 0.8em;
  font-family: inherit;
  font-weight: 500;
  border-radius: 5px;
  padding: 0.4em 0.8em;
  cursor: pointer;
`;

export const PrimaryButton = styled(Button)`
  color: white;
  background-color: #27A3D9;
  border: 1px solid #27A3D9;
`;

export const SecondaryButton = styled(Button)`
  color: #119CD9;
  background-color: white;
  border: 1px solid white;
`;

export const DestructiveButton = styled(Button)`
  color: #FF364E;
  background-color: transparent;
  border: 1px solid #FF364E;
`;
